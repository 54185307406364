<template>
	<basePopup :okFunc="apply" :title="getTitle" okText="적용">
		<vueJsonPretty class="max-h-96 pre-scrollable" :data="previewData" :deep="1" />
	</basePopup>
</template>

<script>
import vueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import basePopup from 'comp/local/basePopup'

export default {
	props: ['type'], //DEAL, MAIN_TOP
	components: { basePopup, vueJsonPretty },
	data() {
		return {
			isShow: false,
			previewData: '',
		}
	},
	methods: {
		open() {
			postApi('/bannerSelect/mainBannerPreview', { bannerType: this.type }).then(res => {
				this.previewData = JSON.parse(res)
			})
			this.isShow = true
		},
		apply() {
			postApi('/batch/mainBannerBatch', { bannerType: this.type }).then(() => {
				alert.s('즉시 적용 되었습니다.')
				this.isShow = false
			})
		},
	},

	computed: {
		getTitle() {
			let res = this.type == 'DEAL' ? '핫딜센터 메인배너' : '핫트메인 상단배너'
			return res + '즉시 적용'
		},
	},
}
</script>
