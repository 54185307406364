<template>
	<div>
		<searchCard>
			<div class="flex">
				<b-form-radio
					v-for="(v, i) in radioOpts"
					class="top-1.5"
					v-model="opt.progress"
					name="searchRadio"
					:value="v.value"
				>
					<span class="mr-6" v-text="v.label" />
				</b-form-radio>

				<b-form-input
					class="w-1/4 ml-12"
					v-model.trim="opt.title"
					name="keyword"
					placeholder="제목 검색"
					@keyup.enter="search"
				/>
				<b-button class="px-10 ml-2" @click="search">검색</b-button>
			</div>
		</searchCard>

		<b-card>
			<b-button class="px-10 mb-2 float-right z-10" @click="bannerUpdateImmediatelyPopup.open()" variant="orange"
				>즉시적용
			</b-button>
			<b-button class="px-10 mb-2 float-right z-10 mr-2" @click="bannerPopup.open()">등록</b-button>
			<tb :inf="inf" :res="res" />
		</b-card>
		<bannerPopup :pr="ths" :type="type" cName="bannerPopup" />
		<bannerUpdateImmediatelyPopup :pr="ths" :type="type" cName="bannerUpdateImmediatelyPopup" />
	</div>
</template>

<script>
import bannerPopup from 'pages/bannerPopup'
import bannerUpdateImmediatelyPopup from 'pages/bannerUpdateImmediatelyPopup'
export default {
	components: { bannerPopup, bannerUpdateImmediatelyPopup },
	data() {
		const radioOpts = [
				{
					label: '전체',
					value: '',
				},
				{
					label: '노출중',
					value: 'SHW',
				},
				{
					label: '노출종료',
					value: 'FIN',
				},
				{
					label: '노출예정',
					value: 'RSV',
				},
			],
			path = this.$route.path.split('/'),
			type = path[path.length - 1].toUpperCase(), //deal, top
			defOpt = {
				progress: radioOpts[0].value,
				title: '',
			}

		let inf = [
				{ title: 'Seq', key: 'seqNo', size: 2 },
				{ title: '제목', key: 'title', size: 16, notCenter: 1 },
				{ title: '순서', key: 'showOrd', size: 2 },
				{ title: 'class', key: 'class', size: 4 },
				{
					title: '상태',
					key: 'progress',
					size: 4,
					addClass: item => {
						if (item.progress == '노출종료') return 'text-gray-light'
						if (item.progress == '노출중') return 'text-primary'
					},
				},
				{ title: '노출시작일', key: 'startShowYmd', size: 6 },
				{ title: '노출종료일', key: 'endShowYmd', size: 6 },
				{ title: '등록일', key: 'createDt', size: 6 },
				{ title: '마지막수정일', key: 'updateDt', size: 6 },
				{
					title: '수정',
					model: 'button',
					icon: 'pencil-square',
					size: 2,
					func: item => {
						this.bannerPopup.open(item)
					},
				},
			],
			orderBy = [...getOrder('showOrd', 'asc'), ...getOrder('mainBannerInfoSeqNo')]
		if (type == 'MAIN_TOP') {
			inf.splice(3, 1)
			//class 제거
			orderBy.splice(1, 1)
		}
		return {
			type,
			radioOpts,
			opt: { ...defOpt },
			defOpt,
			orderBy,
			lastOpt: {},
			res: {},
			inf,
		}
	},
	methods: {
		search() {
			let data = {
				bannerType: this.type,
				orderBy: this.orderBy,
				paging: getPaging(),
			}

			const opt = this.opt

			if (opt.progress) data.progress = opt.progress
			if (opt.title) data.title = opt.title

			this.lastOpt = data
			this.changePage()
		},
		changePage(page = 1) {
			this.lastOpt.paging.pageNo = page - 1
			return postApi('/bannerSelect/mainBannerList', this.lastOpt).then(res => {
				res.list = res.list.map(v => {
					;['startShowYmd', 'endShowYmd'].map(k => (v[k] = v[k] ? v[k].get_time('d') : ''))
					;['createDt', 'updateDt'].map(k => (v[k] = v[k] ? v[k].replace('T', ' ') : ''))
					return v
				})
				this.res = res
				return res
			})
		},
	},
	created() {
		this.search()
	},
}
</script>
