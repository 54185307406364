<template>
	<b-modal v-model="p.isShow" :size="size" centered no-close-on-backdrop>
		<template #modal-title><p v-text="title" /></template>
		<slot />
		<template #modal-footer>
			<slot name="footer">
				<b-button
					v-if="okText"
					class="float-right mr-4 px-10"
					@click="okFunc()"
					:variant="okVariant"
					size="sm"
					v-text="okText"
				/>
				<b-button
					v-if="cancelText"
					class="float-right mr-4 px-10"
					@click="cancel"
					:variant="cancelVariant"
					size="sm"
					v-text="cancelText"
				/>
			</slot>
		</template>
	</b-modal>
</template>

<script>
export default {
	props: {
		title: { defualt: '팝업 타이틀' },
		size: { default: 'lg' },

		okText: { default: '저장' },
		okFunc: { default: () => {} },
		okVariant: { default: 'primary' },

		cancelText: { default: '취소' },
		cancelFunc: { default: false },
		cancelVariant: { default: 'gray' },
	},
	methods: {
		cancel() {
			if (typeof this.cancelFunc == 'function') this.cancelFunc()
			else this.p.isShow = false
		},
	},
}
</script>
