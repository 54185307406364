<template>
	<basePopup :title="getTitle">
		<h6>제목</h6>
		<b-form-input class="" v-model.trim="input.title" name="title" placeholder="제목을 입력해주세요" />

		<h6 class="mt-4" @click="$refs.getImg.click()">이미지 업로드</h6>
		<div v-show="img.data" id="imgBox" @click="$refs.getImg.$refs.input.click()">
			<img class="w-1/1" :src="img.data" alt="img" />
		</div>
		<b-form-file
			v-show="!img.data"
			ref="getImg"
			placeholder="파일을 선택해주세요."
			@change="getImg"
			accept="image/*"
			browse-text="파일 찾기"
		/>
		<!--이건 무려...cName이랑 같은 키값을 쓰고 있어서 cName 적용 하면 안 된다...-->

		<h6 class="block">
			이미지는 {{ img.limitW }}x{{ img.limitH }}에 600KB 이하여야 합니다. (가능한 500KB 이내로 맞춰주세요)
			<br />업로드 한 이미지: {{ img.w }} x {{ img.h }}, {{ img.size }}KB
		</h6>

		<div v-if="type == 'DEAL'">
			<h6 class="mt-4">링크타입</h6>
			<vSelect v-model="input.linkType" :clearable="false" :options="linkTypeOpts" />

			<div v-show="['NL', ''].indexOf(input.linkType.value) == -1">
				<h6 class="mt-4">링크연결 정보</h6>
				<b-form-input
					class=""
					v-model.trim="input.linkInfo"
					name="title"
					placeholder="링크타입에 맞는 정보를 넣어주세요"
				/>
			</div>
		</div>
		<div v-else>
			<h6 class="mt-4">링크연결 정보</h6>
			<b-form-input class="" v-model.trim="input.linkInfo" name="title" placeholder="링크 URL을 입력해주세요" />
		</div>

		<div v-show="type == 'DEAL'">
			<ul>
				<li v-text="input.linkType.def" />
			</ul>

			<h6 class="">노출 class</h6>
			<div class="inline-block top-0.5">
				<b-form-checkbox
					v-for="v in classOpts"
					class="inline-block ml-4"
					v-model="input.classList"
					:value="v"
					>{{ v }}</b-form-checkbox
				>
				<b-button class="-top-1 ml-4" @click="input.classList = classOpts" size="sm" v-text="'일괄 체크'" />
			</div>
		</div>

		<h6 class="mt-4 block">노출기간</h6>
		<div class="flex">
			<datePicker class="w-2/5" model="input.startDate" />
			<span class="p-2 lh-6">~</span>
			<datePicker class="w-2/5" model="input.endDate" />
		</div>

		<h6 class="mt-4">노출순서</h6>
		<vSelect v-model="input.order" :clearable="false" :options="orderOpts" />
		<template #footer>
			<b-button class="float-right mr-4 px-10" @click="save" size="sm" variant="primary" v-text="'저장'" />
			<b-button
				v-show="isEdit"
				class="float-right mr-4 px-10"
				@click="remove"
				size="sm"
				variant="info"
				v-text="'삭제'"
			/>
			<b-button class="float-right mr-4 px-10" @click="isShow = false" size="sm" variant="gray" v-text="'취소'" />
		</template>
	</basePopup>
</template>

<script>
import { uploadFile } from 'libs/axios'
import basePopup from 'comp/local/basePopup'

const linkTypeOpts = [
		{
			label: '공지사항목록',
			value: 'NL',
			def: '공지사항 목록 : 미입력(목록으로 이동합니다.)',
		},
		{
			label: '공지사항',
			value: 'N',
			def: '공지사항 : 공지사항 게시글 번호 (예 : 23)',
		},
		{
			label: '상품그룹',
			value: 'P',
			def: '상품그룹 : 상품idx 번호 (예 : 1708)',
		},
		{
			label: '카테고리번호',
			value: 'C',
			def: '카테고리 번호 : 카테고리 번호 입력(렌탈 : 6, 생활 : 2, 유아 : 1, 반려 : 3, 식품 : 5, 뷰티 : 4)',
		},
		{
			label: '일반 링크',
			value: 'L',
			def: '일반 링크 입력',
		},
		{
			label: '일반 링크( 탭 )',
			value: 'LT',
			def: '일반 링크 입력',
		},
		{
			label: '연결없음',
			value: '',
			def: '연결없음 : 미입력',
		},
	],
	now = new Date(),
	tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1),
	defInput = {
		title: '',
		linkType: linkTypeOpts[0],
		linkInfo: '',
		order: '',
		startDate: tomorrow,
		endDate: tomorrow,
		classList: [],
	}

export default {
	props: ['type'], //DEAL, MAIN_TOP
	components: { basePopup },
	data() {
		return {
			isShow: false,
			isEdit: false,
			seqNo: -1,

			linkTypeOpts,
			orderOpts: Array.from({ length: 10 }, (v, i) => i + 1),
			classOpts: ['1', 'a', 'b', 'c', 'd', 'e'],

			input: { ...defInput },
			img: {},
			url: {
				insert: '',
				update: '',
				delete: '',
			},
		}
	},
	methods: {
		initImg(append = {}) {
			this.img = {
				data: '',
				w: 0,
				h: 0,
				size: 0,
				isEdit: false,
				isPassWH: false,
				limitW: 750,
				limitH: 300,
				...append,
			}
		},
		open(item) {
			if (item) {
				this.seqNo = item.seqNo
				this.isEdit = true
				this.input = {
					title: item.title,
					linkType: getOptsFromValue(linkTypeOpts, item.linkType),
					linkInfo: item.linkInfo,
					order: item.showOrd,
					startDate: new Date(item.startShowYmd.get_time('d')),
					endDate: new Date(item.endShowYmd.get_time('d')),
					classList: item.class ? item.class.split(',') : '',
				}
				this.initImg({ data: item.imageUrl })
			} else {
				this.seqNo = -1
				this.isEdit = false
				this.input = { ...defInput }
				this.initImg()
			}
			this.isShow = true
		},
		save() {
			const input = this.input
			let error = []
			if (!input.title || input.title.length > 20) error.push('제목을 확인해주세요')
			if (!input.order) error.push('노출순서를 확인해주세요')
			if (input.startDate > input.endDate) error.push('노출기간을 확인해주세요')
			if (!this.img.data) error.push('이미지를 확인해주세요')
			if (this.img.isEdit && !this.img.isPassWH) error.push('이미지 사이즈를 확인해주세요')
			if (this.type == 'DEAL') {
				if (!input.classList.length) error.push('class를 선택해주세요')
				switch (input.linkType.value) {
					case 'L':
					case 'LT':
						if (!input.linkInfo) error.push('링크가 입력되지 않았습니다')
						else {
							if (!isUrl(input.linkInfo)) error.push('링크 형식이 아닙니다')
							else if (!input.linkInfo.startsWith('http')) input.linkInfo = 'http://' + input.linkInfo
						}
				}
			}

			if (error.length) alert.w(error)
			else {
				let prevPromise = this.img.isEdit ? uploadFile(this.$refs.getImg.files) : new Promise(r => r())
				prevPromise.then(url => {
					let data = {
						bannerType: this.type,
						title: input.title,
						linkType: getDropdownValue(input.linkType),
						linkInfo: input.linkInfo,
						showOrd: getDropdownValue(input.order),
						startShowYmd: input.startDate.get_time('d').replace(/-/g, ''),
						endShowYmd: input.endDate.get_time('d').replace(/-/g, ''),
						adminSeq: layout.user.seqNo,
					}
					if (this.type == 'DEAL') data.class = input.classList.join(',')
					if (this.img.isEdit) data.imageUrl = url

					let targetMethod, targetUrl
					if (this.isEdit) {
						targetUrl = '/bannerHandle/updateMainBanner'
						targetMethod = putApi
						data.seqNo = this.seqNo
					} else {
						targetUrl = '/bannerHandle/insertMainBanner'
						targetMethod = postApi
					}

					targetMethod(targetUrl, data).then(() => {
						alert.s(
							'변경한 배너정보는 금일 자정에 반영됩니다.\r\n즉시반영을 원하실 경우 관리자에게 요청해주세요.'
						)
						this.isShow = false
						reload(this, true)
					})
				})
			}
		},

		getImg(input) {
			const target = input.target
			if (target.files && target.files[0]) {
				const reader = new FileReader()
				// const img = new Image()
				reader.onload = e => {
					this.img.data = e.target.result
					// start of img
					const img = new Image()
					img.onload = e => {
						this.img.w = e.target.width
						this.img.h = e.target.height
						this.img.isPassWH = e.target.width == this.img.limitW && e.target.height == this.img.limitH
					}
					img.src = reader.result
				}
				const maxSize = 600 * 1024

				if (target.files[0].size > maxSize) {
					alert.w('업로드 이미지파일 크기는 600KB 보다 작아야 합니다.')
					return
				}
				this.img.size = Math.ceil(target.files[0].size / 1024)
				reader.readAsDataURL(target.files[0])
				this.img.isEdit = true
			}
		},
		remove() {
			putApi('/bannerHandle/deleteMainBanner', {
				seqNo: this.seqNo,
				adminSeq: layout.user.seqNo,
			}).then(() => {
				alert.s('삭제되었습니다.')
				this.isShow = false
				reload(this, true)
			})
		},
	},

	computed: {
		getTitle() {
			let res = this.type == 'DEAL' ? '핫딜센터 메인배너' : '핫트메인 상단배너'
			return (res += this.isEdit ? ' 수정' : ' 등록')
		},
	},
}
</script>

<style>
#imgBox {
	min-height: calc(1.5em + 0.75rem + 2px);
	max-height: 20rem;
	overflow-y: scroll;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}
</style>
